import { graphql } from 'gatsby';
import React from 'react';
import BodyText from '../components/body-text/BodyText';
import Layout from '../components/layout/Layout';
import { EducationFrontmatterType, IEducationResponse } from '../types/educationTypes';
import * as styles from '../pages/education/education.module.scss';
import classNames from 'classnames';

type EducationTemplateProps = {
  html: string,
  frontmatter: EducationFrontmatterType
}

export const EducationTemplate: React.FC<EducationTemplateProps> = ({ html, frontmatter }) => {
  const { title, subtitle, featuredImage } = frontmatter;

  return (
    <main className={styles.education}>
      <div className={classNames(classNames, styles.educationContainer, 'wrapper')}>
        <img className={styles.educationImage} src={featuredImage} alt="Education image" />
        <h1 className={styles.educationTitle}>{title}</h1>
        <p className={styles.educationSubtitle}>{subtitle}</p>
        <BodyText html={html} />
      </div>
    </main>
  )
}

type EducationContainerProps = {
  data: IEducationResponse
}

const EducationContainer: React.FC<EducationContainerProps> = ({ data }) => {
  const { html, frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <EducationTemplate html={html} frontmatter={frontmatter} />
    </Layout>
  )
}

export default EducationContainer;


export const educationKeyByIdQuery = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        subtitle
        featuredImage
      }
    }
  }
`