import classNames from 'classnames';
import classnames from 'classnames';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import React, { useEffect, useState } from 'react';
import { pagePathEnum } from '../../enums/pathEnum';
import * as styles from './header.module.scss';


const Header: React.FC = ({
}) => {
   const [currentUrl, setCurrentUrl] = useState<string>('');
   useEffect(() => {
      const mobileMenu = document.getElementById('mobile-menu');
      const mobileMenuModal = document.getElementById('mobile-menu-modal');
   
      if (mobileMenu && mobileMenuModal) {
         mobileMenu.addEventListener('click', function () {
            mobileMenu.classList.toggle('open');
            mobileMenuModal.classList.toggle('open');
         })
      }
   }, []);
  
   useEffect(() => {
      const url = window.location.pathname;
      setCurrentUrl(url);
   }, [])

   const urls = [
      {
         name: 'Home',
         url: pagePathEnum.HOME
      },
      {
         name: 'About Us',
         url: pagePathEnum.ABOUT_US
      },
      {
         name: 'Services',
         url: pagePathEnum.SERVICES
      },
      {
         name: 'Careers',
         url: pagePathEnum.CAREERS
      },
      {
         name: 'Contact Us',
         url: pagePathEnum.CONTACT_US
      }
   ]
   return (
      <header className={styles.header}>
         <div className={classNames(classNames, styles.headerContainer, 'wrapper')}>
            <div className={styles.headerContent}>
               <StaticImage 
                  src={'../../images/section-backgroung/logo-main.png'} 
                  alt="Logo of this page" 
                  placeholder="tracedSVG" 
               />
               <nav className={styles.nav}>
                  <ul className={styles.navList}>
                     {urls.map(url => <li 
                        className={classnames(styles.navItem, {
                           [styles.itemActive]: currentUrl === url.url
                        })} 
                        key={url.name}
                     >
                        <Link to={url.url}>{url.name}</Link>
                     </li>)}
                  </ul>
               </nav>
            </div>

            <div className={styles.mobileMenu} id="mobile-menu">
               <StaticImage 
                  className={styles.menuMobileOpen} 
                  src={'../../images/mobile-menu.png'} 
                  alt="Open mobile menu" 
               />
               <StaticImage 
               className={styles.menuMobileClose} 
               src={'../../images/close.png'}
               alt="Close mobile menu"
               placeholder="tracedSVG"
               />
            </div>

            <span className={styles.headerPhone}>
               <span className={styles.headerCall}>
                  <StaticImage 
                     src={'../../images/call.png'} 
                     alt="" 
                     aria-hidden="true"
                     placeholder="tracedSVG"
                  />
               </span>
               <a href={'tel:+44 161 818 6966'}>+44 161 818 6966</a>
            </span>
            <div className={styles.mobileMenuModal} id="mobile-menu-modal">
               <nav className={styles.nav}>
                  <ul className={styles.navList}>
                     {urls.map(url => <li 
                        className={classnames(styles.navItem, {
                           [styles.itemMobileActive]: currentUrl === url.url
                        })} 
                        key={url.name}
                     >
                        <Link to={url.url}>{url.name}</Link>
                     </li>)}
                  </ul>
               </nav>
            </div>
         </div>
      </header>
   );
};

export default Header;
