import classnames from 'classnames';
import React from 'react';
import * as styles from './body-text.module.scss';

type BodyTextProps = {
    html: string,
    className?: string
}

const BodyText: React.FC<BodyTextProps> = ({ html }) => {
    return (
        <div className={classnames(styles.text)} dangerouslySetInnerHTML={{ __html: html || '' }}/>
    )
}

export default BodyText