// extracted by mini-css-extract-plugin
export var banner = "footer-module--banner--3Gm-i";
export var bottom = "footer-module--bottom--gvAVp";
export var footer = "footer-module--footer--lU7l1";
export var footerContainer = "footer-module--footer-container--oUD3x";
export var infoList = "footer-module--info-list--a+055";
export var infoListItem = "footer-module--info-list-item--IRLk1";
export var itemPhone = "footer-module--item-phone--ZzKU4";
export var itemPlace = "footer-module--item-place--ucOvc";
export var list = "footer-module--list--iVPne";
export var listImg = "footer-module--list-img--KeFjW";
export var listItem = "footer-module--list-item--BQ1Hs";
export var listText = "footer-module--list-text--mFMEs";
export var listTitle = "footer-module--list-title--v5gD9";
export var logoImg = "footer-module--logo-img--cG3xZ";
export var socials = "footer-module--socials--ulDVD";
export var socialsItem = "footer-module--socials-item--qz0+u";
export var top = "footer-module--top--r1jXc";