import { graphql, StaticQuery } from 'gatsby';
import React from 'react';
import Footer from '../footer/Footer';
import Header from '../header/Header';

const Layout: React.FC = ({children}) => {
    return (
        <StaticQuery query={graphql`
          query {
            allMarkdownRemark(
                filter: {frontmatter: {templateKey: {eq: "service-page-template"}}}
              ) {
                edges {
                  node {
                    id
                    frontmatter {
                        templateKey
                        path
                        title
                    }
                  }
                }
              }
            }
          `}
          render={data => <>
            <Header />
            {children}
            <Footer services={data?.allMarkdownRemark.edges.map((el: any, i: number) => ({ 
                path: el.node.frontmatter.path,
                title: el.node.frontmatter.title,
                id: el.node.id
            }))} />
          </>}
        />
    );
};

export default Layout;