import classNames from 'classnames';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { pagePathEnum } from '../../enums/pathEnum';
import * as styles from './footer.module.scss';

type FooterProps = {
   services: { id: string, path: string, title: string }[]
}

const Footer: React.FC<FooterProps> = ({ services }) => {
   const urls = [
      {
         name: 'Home',
         url: pagePathEnum.HOME
      },
      {
         name: 'About Us',
         url: pagePathEnum.ABOUT_US
      },
      {
         name: 'Services',
         url: pagePathEnum.SERVICES
      },
      {
         name: 'Careers',
         url: pagePathEnum.CAREERS
      },
      {
         name: 'Contact Us',
         url: pagePathEnum.CONTACT_US
      }
   ];
   const sortedServices = services.sort((a, b) => a.title.localeCompare(b.title));
   return (
      <footer className={styles.footer}>
         <div className={classNames(classNames, styles.footerContainer, 'wrapper')}>
            <div className={styles.top}>
               <div className={styles.banner}>
                  <StaticImage 
                     className={styles.logoImg} 
                     src={'../../images/section-backgroung/logo-secondary.png'} 
                     alt="Logo image"
                     placeholder="tracedSVG"
                  />
                  <ul className={styles.socials}>
                     <li className={styles.socialsItem}>
                        <Link to={'/'}>
                           <StaticImage 
                              src={'../../images/twitter.png'} 
                              alt="" 
                              aria-hidden="true"
                              placeholder="tracedSVG"
                           />
                        </Link>
                     </li>
                     <li className={styles.socialsItem}>
                        <Link to={'/'}>
                           <StaticImage 
                              src={'../../images/linkedin-in.png'} 
                              alt="" 
                              aria-hidden="true"
                              placeholder="tracedSVG" 
                           />
                        </Link>
                     </li>
                     <li className={styles.socialsItem}>
                        <Link to={'/'}>
                           <StaticImage 
                              src={'../../images/instagram.png'} 
                              alt="" 
                              aria-hidden="true"
                              placeholder="tracedSVG" 
                           />
                        </Link>
                     </li>
                  </ul>
               </div>
               <div className={styles.list}>
                  <p className={styles.listTitle}>Quick Link</p>
                  <ul>
                     {urls.map(url => <li className={styles.listItem} key={url.name}><Link to={url.url}>{url.name}</Link></li>)}
                  </ul>
               </div>
               <div className={styles.list}>
                  <p className={styles.listTitle}>Services</p>
                  <ul>
                     {sortedServices.map(el => <li 
                        key={el.id} 
                        className={styles.listItem}
                     >
                        <Link to={el.path}>
                           {el.title}
                        </Link>
                     </li>)}
                  </ul>
               </div>
               <div className={styles.list}>
                  <p className={styles.listTitle}>Contact Us</p>
                  <ul>
                     <li className={classNames(classNames, styles.listItem, styles.itemPhone, styles.listText)}>
                        UK: <a href={'tel:+44 161 818 6966'}>+44 161 818 6966</a>
                     </li>
                     <li className={classNames(classNames, styles.listItem, styles.itemPlace, styles.listText)}>
                        PL: <a href={'tel:+48 12 361 3670'}>+48 12 361 3670</a>
                     </li>
                  </ul>
               </div>
            </div>
            <div className={styles.bottom}>
               <p>Copyright © 2023 AL Core Technologies.</p>
               <ul className={styles.infoList}>
                  <li className={styles.infoListItem}><Link to={'/careers'}>Privacy Policy</Link></li>
                  <li className={styles.infoListItem}><Link to={'/careers'}>Terms &#38; Conditions</Link></li>
               </ul>
            </div>
         </div>
      </footer>
   );
};

export default Footer;
